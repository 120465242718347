.article-page-container{
    display: flex;
    justify-content: center;
 
}   

.article-page-container .middle-container{
    background-color: transparent;
    max-width: 850px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--lighter-background);
    padding: 2rem;
    margin-top: 5rem;
    overflow: hidden;

}

.article-page-container .video-container{
    margin-top: 2rem;
    background-color: var(--lighter-background);
    width: 650px;
    height: 450px;
    align-self: center;
    border: none;
    outline: none;
    position: relative;
}

.article-page-container #article-image{
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center;
}

.article-page-container .article-info{
    display: flex;
    text-overflow: clip;
    overflow-wrap: break-word;
    margin-top: 1rem;
    flex-direction: column;
    color: var(--text-color);
}

.article-page-container .article-title{
    text-align: center;
}

.article-page-container .author-name{
    
}

.article-page-container .article-description{
    display: inline-block;
    word-break: break-word;
}




#comment-header{
color: white;
width: 300px;
margin-left: 1rem;
}
#commentinfobarcontainer{
    position: relative;
}
#commentinfobar{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    flex-direction: column;
    bottom: 0;
}
#commenttext{
    min-height: 100px;
    outline: none;
    width: 80%;
    background-color: var(--lighter-background);
    border: 1px solid var(--light-blue);
    padding: 1rem;
    color: var(--text-color);
    border-radius: 5px;
    resize: none;
}

#commentbutton{
    min-height: 40px;
    width: 100px;
    margin-top: 10px;
}

#logincommentspan{
    position: absolute;
    user-select: none;
    color: var(--text-color);
    font-size: 2rem;
}

#comments-container{
    width: 750px;
    height: auto;
    align-self: center;
    background-color: var(--background-color);  
    margin-top: 4rem;  
    border-radius: 5px;
    position: relative;
}
.comment-container{
    padding: .5rem;
    margin-left: 17px;
    border-radius: 5px;
    color: var(--text-color);
    width: 94%;
    border: 1px solid var(--text-color);
    margin:20px;
    align-self: center;
    justify-self: center;

}

.comment-container h3{
    margin-top: 0;

}

.comment-container span{
    overflow-wrap: break-word;
}

@media(max-width:800px){
    .article-page-container .middle-container{
        width: 80%;
        
    }
    .article-page-container .video-container{
        width: 100%;
        height: 300px;
    }
    #comments-container{
        width: 100%;

    }
    .comment-container{
        width: 80%;
    }
}