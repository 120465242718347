.edit-input {
    max-width: 200px;
    font-size: 1.1rem;
    padding: .7rem;
    width: 90%;
    margin: .5rem;
    padding-left: .8rem;
    background-color: var(--input-color);
    color: var(--text-color-white);
    outline: none;
    border: none;
    border-bottom: 1px solid var(--text-color-black);
    border-radius: 5px;
}

.edit-input::placeholder {
    color: var(--text-color);
}

#search-inputs-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
}

#no-articles {
    z-index: 1;
    color: var(--text-color-white);
    margin-top: 20rem;
}

#edit-articles-container {
    margin-top: 17rem;
}

.edit-page-container {
    position: relative;
}

#edit-articles-container #article-container {
    margin: 2rem;
    width: 500px;
    height: 150px;
    background-color: var(--lighter-background);
    color: var(--text-color-white);
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

#edit-articles-container #article-container img {
    width: 130px;
    height: 130px;
    margin-left: 1rem;
}

#article-info-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1rem;
}

#edit-search {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
}

#edit-search-container {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    background: var(--lighter-background);
    justify-content: center;
    border-radius: 5px;
    padding-bottom: 1rem;
    position: absolute;
}

#article-info-container span {
    margin: .15rem;
}

#selected-edit-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 5.5rem;
    position: relative;
}

#inside1 {
    display: flex;
    margin-top: -1.1rem;
    justify-content: space-evenly;
    position: fixed;
    left: 0;
    width: 70%;
}

@media(max-width:1660px) {
    .selected-item-info-container {
        min-width: 600px;
    }
    #inside1 {
        flex-direction: column-reverse;
        position: relative;
    }
}

@media(max-width:1330px) {
    .selected-item-infos {
        display: block;
        margin: 1rem;
    }
    #inside1 {
        position: relative;
        align-items: center;
    }
    #edit-search {
        position: relative;
        margin-top: 2rem;
    }
    #selected-edit-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 5.5rem;
        position: relative;
    }
}

@media(max-width:700px) {
    .selected-item-info-container {
        min-width: 100%;

  
    }
    #inside1 {
        position: relative;
        align-items: center;
        width: 100%;
    }

    #edit-search-container {
        width: 350px;
        font-size: .8rem;
    }
    #edit-articles-container #article-container {
        width: 350px;
    }
    #article-info-container span {
        margin: .15rem;
    }
    .edit-input {
        width: 40%;
    }
}