#weather-container {
    user-select: none;
    text-align: center;
    position: absolute;
    right: 11rem;
    width: 70px;
}

#weather-container span {
    text-align: center;
}

#lighter-outside {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    height: 100vh;
    margin-top: -75px;
    position: fixed;
    width: 100%;
    z-index: 1;
}

#close-login-button {
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    padding: .5rem 1rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    outline: none;
}

.login-container {
    width: 450px;
    height: 250px;
    background-color: var(--lighter-background);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    user-select: none;
    box-shadow: 1px 1px 0 0;
}

#close-login-button:hover {
    color: var(--text-color);
}

.login-form {
    margin-top: -2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#rem-container {
    margin-top: .2rem;
    width: 77%;
    display: inline-flex;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    justify-content: flex-end;
}

#remember-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

#forgot-pass {
    color: var(--light-blue);
}

.login-button {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: var(--light-blue);
    cursor: pointer;
    color: var(--text-color-white);
    position: relative;
}

#login-button-container {
    top: 12rem;
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
    flex-direction: column;
}

.bottom-text {
    margin-top: 6px;
    font-size: 11px;
    color: var(--text-color);
    cursor: pointer;
}

#signup-form {
    margin-top: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#signup-container {
    width: 450px;
    height: 370px;
    background-color: var(--lighter-background);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    user-select: none;
}

#name-container {
    display: inline-flex;
    justify-content: space-between;
    width: 350px;
}

.name-input {
    font-size: 1.1rem;
    padding: .6rem;
    padding-left: .8rem;
    background-color: var(--input-color);
    color: var(--text-color-white);
    outline: none;
    border: none;
    border-bottom: 1px solid var(--text-color-black);
    border-radius: 5px;
    width: 165px;
}

.name-input::placeholder {
    color: var(--text-color);
}

#signup-button-container {
    top: 19.5rem;
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
    flex-direction: column;
}

.error-container {
    width: 100%;
    text-align: center;
    color: #833d3d;
    margin-top: 14rem;
    position: absolute;
}

.error-container-login {
    width: 100%;
    text-align: center;
    color: #833d3d;
    margin-top: 7rem;
    position: absolute;
}

.loading-span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading {
    z-index: 1;
    width: 22px;
    height: 22px;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.email-sent-span {
    color: white;
    font-size: 3rem;
    margin-top: 3rem;
    font-weight: bold;
}

.home-button {
    height: 36px;
    width: 100px;
    position: absolute;
    border-radius: 5px;
    outline: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    border: none;
    cursor: pointer;
    left: 1rem;
    border: 1px solid #0E0E0E;
}

.search-input::placeholder {
    color: var(--text-color);
}

#open-login-button {
    height: 36px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    border: none;
    cursor: pointer;
    margin-top: 2rem;
    position: absolute;
    right: 10px;
}

.moon-icon-login {
    height: 36px;
    width: 40px;
    position: absolute;
    border-radius: 5px;
    right: 8.1rem;
    outline: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    border: none;
    cursor: pointer;
    margin-top: 2rem;
}


.grid-icon-login {
    height: 36px;
    width: 40px;
    right: 11.1rem;
    position: absolute;
    border-radius: 5px;
    outline: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    border: none;
    cursor: pointer;
    margin-top: 2rem;
}

#nav-container {
    background-color: var(--lighter-background);
    height: 65px;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 100%;
    box-shadow: 0 0 1px 1px #18181b;
    color: var(--text-color-white);
    z-index: 10;
    top: 0;
 
}


#dropdown-menu ul {
    width: 130%;
    align-self: center;
    margin-top: 0px;
    height: auto;
    text-align: center;
    padding: 0;
    border-top: none !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1.3px solid var(--text-color-black);
    margin-top: 4rem;
    position: absolute;
    right: 10px;
}

#dropdown-menu li {
    width: 100%;
    list-style: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    cursor: pointer;
}

#dropdown-menu li button {
    width: 100%;
    outline: none;
    border: none;
    padding: .5rem 0;
    list-style: none;
    background-color: var(--background-color);
    color: var(--text-color-white);
    cursor: pointer;
}

#dropdown-menu li:hover {
    background-color: var(--lighter-background);
}

.nav-dropdown-container {
    position: relative;
    align-self: flex-end;
    height: 300px;
    width: 110px;
    border-radius: 4px;
    top: -1rem;
    background-color: transparent;
}



.search-input {
    
    font-size: 1rem;
    outline: none;
    padding: .5rem;
    border-radius: 4px;
    background-color: var(--input-color);
    border: none;
    padding-left: 4.1rem;
  /*   padding-left: .8rem; */
    color: var(--text-color);
    border: 1px solid #131419;
}

.dropdown-search {
    position: absolute;
    display: flex;
    flex-direction: column;
     width: 200px; 
    left: 150px;
    top: 1rem;
 /*    width: 170px; */
    
}

.dropdown-search-content {
    
    display: none;
    position: absolute;
    background-color: transparent;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 170px;
    margin-top: 2rem;
}

.dropdown-search:hover .dropdown-search-content{
    display: block;
}

.dropdown-search-content .search-buttons{
    background-color: var(--input-color);
    color: var(--text-color-white);
    cursor: pointer;
    outline: none;
    border: none;
    width: 100%;
    padding: .5rem;
}

.dropdown-search-content .search-buttons:hover{
    background-color: var(--light-blue);
}


@media(max-width:600px){
    .set-buttons{
        display: none;
    }
  
}

@media(max-width:470px){
    .dropdown-search{
        left: 120px;
        width: 125px;
    }
}
#search-display{
    position: absolute;
    
    height: 1.7rem;
    
    margin-left: 4px;
    width: 60px;
    border-radius: 5px;
    user-select: none;
    top: 50%;
    transform: translateY(-50%);
    opacity: .9;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    background-color: var(--background-color);
}

#search-display span{
    position: absolute;
    text-align: center;
    color: var(--text-color);
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    
}