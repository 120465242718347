
.home-all-articles-container{
    position: relative;
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    scroll-behavior: smooth;
    flex-direction: row;
}



.home-articles-container a{
    position: relative;
    width: 100%;
    height: 80%;
}


.topic-anchor{
    max-width: 280px;
}
.home-articles-container .boxes-image{
    width: 100%;
    height: 100%;
    padding: 5px;
} 
.home-articles-container .topic-image{
    width: 280px;
    height: 100%;
    max-height: 240px;
    padding: 5px;
}

.home-articles-container span{
    color: var(--text-color);
    width: 100%;
    padding: 5px;
    overflow: hidden;
}

.topic-title{
    color: var(--text-color-white);
    font-weight: bold;

}

.topics-container{
    display: flex; 
    flex-direction: column;
    overflow: hidden;
    width: 90vw;
    font-weight: bold;
}

.topic-articles{
    display: flex;
    flex-direction: row;
    position: relative;
    overflow-x: hidden;
    justify-content:left;
    scroll-behavior:smooth;
    -webkit-overflow-scrolling: auto;
    scroll-snap-type: x mandatory;
}

.home-articles-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    background-color: var(--lighter-background);
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
    margin-right: 10px;
}

.topic-articles > div {
    scroll-snap-align: center;
  }

.slide-article-button{
    position: fixed;
    height: 100px;
    width: 50px;
    background-color: rgba(0, 0, 0, .8);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 1.4rem;
    outline: none;

    color: var(--text-color-white);
   
}

.topic-articles:hover > .slide-button-container-left{
    opacity: 1;
}
.topic-articles:hover > .slide-button-container-right{
    opacity: 1;
}

.slide-button-container-left{
    position: absolute;
    top: 32%;
    left: 1%;
    opacity: 0;
}

.slide-button-container-right{
    position: absolute;
    top: 32%;
    right: 75px;
    opacity: 0;
}

@media(max-width: 700px) {
    .slide-button-container-left{
        opacity: 1;
    }
    .slide-button-container-right{
        opacity: 1;
        right:  55px;
    }
}