.resetpass-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.resetpass-mid{
    width: 400px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--lighter-background);
    border-radius: 5px;

}