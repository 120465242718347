body {
  margin: 0;
  background-color: var(--background-color) ;
}

*{
  box-sizing: border-box;

}

